"use client";
import React, { useState } from "react";
import { useRouter } from "next/navigation";
import { FaFacebookF, FaWhatsapp, FaYoutube } from "react-icons/fa";
import { TypewriterEffect } from "../../ui/TypeWriterEffect";
import { useForm } from "react-hook-form";
import axios from "axios";
import { prodServerUrl } from "@/app/utils/globals";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import Link from "next/link";
import TransparentButton from "@/components/layout/Button/TransparentButton";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";

const HeroSection = () => {
  const [disable, setDisable] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    selectedCourse: "",
  });
  const [error, setError] = useState("");
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const router = useRouter();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name && !phone && !selectedCourse) {
      alert("All Fields Are Necessary");
      return;
    }

    try {
      const res = await axios.post(`${prodServerUrl}/hero-forms`, formData);

      if (res.status === 201) {
        alert("We have received your query. You will be contacted soon");
        setError("");
        setFormData({
          name: "",
          phone: "",
          selectedCourse: "",
        });
        router.push("/thank-you");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const words = [
    {
      text: "Your",
    },
    {
      text: "Online",
    },
    {
      text: "Finishing",
    },
    {
      text: "School",
    },
    {
      text: "Is",
    },
    {
      text: "Vidyastu.",
      className: "text-orange-200 dark:text-orange-400",
    },
  ];
  return (
    <div className="w-full h-[700px] md:h-[650px] bg-cover bg-center bg-hero-pattern">
      <div className="w-full h-[700px] md:h-[650px] bg-slate-700/30 backdrop-brightness-[0.4] flex flex-col-reverse md:flex-row justify-around items-start">
        {/* main content */}
        <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56">
          {/* social icons */}
          {/* <div className="flex justify-end">
            <div className="flex justify-center items-center w-[30px] h-[30px] bg-green-600 rounded-full">
              <Link href="https://api.whatsapp.com/send/?phone=919051977167&text=%22I%20am%20from%20your%20website.%22&type=phone_number&app_absent=0">
                <FaWhatsapp className="text-white text-lg" />
              </Link>
            </div>
            <div className="flex justify-center items-center w-[30px] h-[30px] bg-blue-600 rounded-full mx-2">
              <Link
                href="https://www.facebook.com/vidyastueducation"
                target="_blank"
              >
                <FaFacebookF className="text-white text-lg" />
              </Link>
            </div>
            <div className="flex justify-center items-center w-[30px] h-[30px] bg-red-600 rounded-full">
              <Link
                href="https://www.youtube.com/@vidyastu1787"
                target="_blank"
              >
                <FaYoutube className="text-white text-lg" />
              </Link>
            </div>
          </div> */}

          {/* hero content */}
          <div className="text-center mt-0 font-Poppins font-semibold">
            <TypewriterEffect words={words} />
            {/* <p className="text-2xl md:text-6xl text-white">
              ENROLL.
              <span className="text-[#FFD966]"> LEARN.</span> CREATE
            </p> */}
            <h1 className="text-white text-sm text-start mt-7 font-light">
              Vidyastu empowers careers with high-quality, interactive courses,
              currently having{" "}
              <span className="text-sm text-[#FFD966]">9500+</span> students.
            </h1>
            <div className="text-start mt-8">
              <TransparentButton href="/about" />
            </div>
          </div>
        </div>
        <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56">
          {/* hero content */}
          <div className="text-center font-Poppins p-6 rounded backdrop-blur-[178px] border border-zinc-600">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col justify-center items-center">
                <h1 className="text-zinc-300 text-2xl md:text-4xl mb-12 text-start font-bold">
                  Learn New Age Skills Online
                </h1>
              </div>

              <div className="mb-1 font-Poppins">
                {/* <input
                  type="text"
                  className="w-full h-9 p-4 text-sm bg-white border border-zinc-400 focus:outline-none text-zinc-700"
                  onChange={(e) => setSelectedCourse(e.target.value)}
                  placeholder="Which Course/(s) are you interested in?"
                  required
                /> */}
                <select
                  id="selectedCourse"
                  name="selectedCourse"
                  value={formData.selectedCourse}
                  onChange={handleChange}
                  className="shadow border rounded py-2 px-3 text-gray-400 w-full focus:outline-none focus:shadow-outline text-zinc-700 text-sm"
                >
                  <option value="">Select A Course</option>
                  <option value="ui ux">UI/UX & Graphic Design</option>
                  <option value="digital marketing">Digital Marketing</option>
                  <option value="data science">Data Science & Analytics</option>
                  <option value="content writing">Content Writing</option>
                  <option value="web development">Full Stack Web Development</option>
                  {/* <option value="springboot">Java Springboot</option> */}
                </select>
              </div>
              <div className="mb-1 font-Poppins">
                <input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  type="text"
                  className="w-full h-9 p-4 text-sm bg-white border border-zinc-400 focus:outline-none text-zinc-700"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="mb-1 font-Poppins">
                <input
                  id="phone"
                  name="phone"
                  type="number"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full h-9 p-4 text-sm bg-white border border-zinc-400 focus:outline-none text-zinc-700"
                  placeholder="Your Phone"
                  required
                />
              </div>
              <div className="text-center font-Poppins">
                <button
                  className='px-4 py-2 my-3 font-medium text-sm border border-[#FFD966] text-white rounded shadow hover:bg-colorAqua hover:border-colorAqua w-full'
                  type="submit"
                  disabled={disable ? true : false}
                >
                  Submit
                </button>
              </div>
            </form>
            {/* <LeadFormForHero /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
