"use client";
import AboutUsSection from "../components/home/about-us/AboutUsSection";
import CallToActionSection from "../components/home/call-to-action/CallToActionSection";
import CollaboratedCompanySection from "../components/home/collaborated-company/CollaboratedCompanySection";
import EventSection from "@/components/home/event/EventSection";
import FacultySection from "../components/home/faculty/FacultySection";
import HeroSection from "../components/home/hero/HeroSection";
import KeyFeaturesSection from "../components/home/key-features/KeyFeaturesSection";
import LeadFormSection from "../components/home/lead-form/LeadFormSection";
import OurCoursesSection from "../components/home/our-courses/OurCoursesSection";
import SuccessStorySection from "../components/home/success-stories/SuccessStorySection";
import FreeCourses from "../components/home/free-courses/FreeCourses";
import Founder from "../components/home/founder/Founder";
import Internship from "@/components/home/internship/Internship";
import WhatsAppButton from "@/components/common/WhatsAppButton";
import DownloadOurApp from "@/components/common/DownloadOurApp";
import NewsChannel from "@/components/home/news/NewsChannel";
import CustomHead from "@/utils/CustomHead";
import { Slider } from "@/components/home/hero-slider/Slider";
import { CardReveal } from "@/components/home/cards/CardReveal";
import RequestCallback from "@/components/common/RequestCallback";
import { useRouter } from "next/navigation";
import { useState, useEffect } from "react";
import { prodServerUrl } from "@/app/utils/globals";
import { IoCloseCircleOutline } from "react-icons/io5";
import Image from "next/image";
import { Review } from "@/components/home/reviews/Review";
import axios from "axios";

export default function page() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Set a timeout to delay the popup display
    const timeout = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Display popup after 1 second

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  const [disable, setDisable] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    selectedCourse: "",
  });
  const [error, setError] = useState("");
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const router = useRouter();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name && !phone && !selectedCourse) {
      alert("All Fields Are Necessary");
      return;
    }

    try {
      const res = await axios.post(`${prodServerUrl}/hero-forms`, formData);

      if (res.status === 201) {
        alert("We have received your query. You will be contacted soon");
        setError("");
        setFormData({
          name: "",
          phone: "",
          selectedCourse: "",
        });
        router.push("/thank-you");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        {/* <Slider /> */}
        <HeroSection />
        {/* <KeyFeaturesSection /> */}
        <AboutUsSection />
        <EventSection />
        <CollaboratedCompanySection />
        <OurCoursesSection />
        <LeadFormSection />
        <FreeCourses />
        <FacultySection />
        {/* <CardReveal /> */}
        {/* <NewsChannel /> */}
        <CallToActionSection />
        {/* <Founder /> */}
        <SuccessStorySection />
        {/* <Review /> */}
        {/* <Internship/> */}
        <WhatsAppButton />
        {/* <DownloadOurApp /> */}
        {/* <RequestCallback /> */}
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <form
            onSubmit={handleSubmit}
            className="p-6 rounded backdrop-blur-[178px] border border-zinc-600"
          >
            <div className="flex flex-col justify-center items-center">
              <h5 className="uppercase tracking-widest text-blue-600 font-bold text-3xl">
                vidya<span className="text-yellow">s</span>tu
              </h5>
            </div>

            <p className="text-blue-600 my-3 text-xs text-center font-Poppins">
              Your Online Finishing School
            </p>
            <h5 className="font-Poppins text-md font-bold text-zinc-200 mb-3 text-center md:text-start">
              Admission is Open for {" "}
              <span className="text-lg text-blue-600">UI/UX & Graphic Design</span> and {" "}
              <span className="text-lg text-yellow">Digital Marketing</span>.
            </h5>
            <div className="mb-1 font-Poppins">
              <select
                id="selectedCourse"
                name="selectedCourse"
                value={formData.selectedCourse}
                onChange={handleChange}
                className="shadow border rounded py-2 px-3 text-gray-400 w-full focus:outline-none focus:shadow-outline text-zinc-700 text-sm"
              >
                <option value="">Select a course</option>
                <option value="ui ux">UI/UX & Graphic Design</option>
                <option value="digital marketing">Digital Marketing</option>
                {/* <option value="data science">Data Science & Analytics</option> */}
                {/* <option value="content writing">Content Writing</option> */}
                {/* <option value="web development">Full Stack Web Development</option> */}
                {/* <option value="springboot">Java Springboot</option> */}
              </select>
            </div>
            <div className="mb-1 font-Poppins">
              <input
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                type="text"
                className="w-full h-9 p-4 text-sm bg-white border border-zinc-400 focus:outline-none text-zinc-700"
                placeholder="Your Name"
                required
              />
            </div>
            <div className="mb-1 font-Poppins">
              <input
                id="phone"
                name="phone"
                type="number"
                value={formData.phone}
                onChange={handleChange}
                className="w-full h-9 p-4 text-sm bg-white border border-zinc-400 focus:outline-none text-zinc-700"
                placeholder="Your Phone"
                required
              />
            </div>
            <div className="flex flex-row justify-between items-center gap-3 font-Poppins">
              <button
                className="text-sm font-Josefin font-medium px-4 py-2 mt-4 w-full border border-zinc-400 text-zinc-100 rounded-lg"
                onClick={() => setShowPopup(false)}
              >
                Close
              </button>
              <button
                className={`${disable ? "bg-colorTextSecondary" : "bg-blue-900"
                  } text-sm font-Josefin font-medium px-4 py-2 mt-4 w-full text-zinc-100 rounded-lg`}
                type="submit"
                disabled={disable ? true : false}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
