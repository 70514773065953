"use client";

import React, { useEffect, useRef, useState } from "react";
import EventCard from "./EventCard";
import axios from "axios";
import { prodServerUrl } from "@/app/utils/globals";
import Link from "next/link";

const EventSection = () => {
  const didRunOnce = useRef(false);
  const [event, setEvent] = useState([]);

  const fetchKeyEvent = async () => {
    try {
      const { data } = await axios.get(`${prodServerUrl}/key-events`, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });

      if (data.success === true) {
        setEvent(data.keyEvents);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (!didRunOnce.current) {
      fetchKeyEvent();
      didRunOnce.current = true;
    }
  });

  return (
    <div>
      <div className="w-full bg-cover bg-center bg-bg-homeevent">
        <div className="w-full bg-slate-700/30 backdrop-brightness-50">
          <div className="absolute triangle -mt-4">
            <div className="w-16 overflow-hidden inline-block">
              <div className="h-[480px] bg-yellow rotate-45 transform origin-top-right shadow-md shadow-slate-800"></div>
            </div>
          </div>

          <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56">
            {event.length > 0 ? (
              event.map((eve, i) => {
                return (
                  <EventCard
                    key={i}
                    title={eve.title}
                    subtitle={eve.subtitle}
                    date={eve.date}
                    dayOfWeek={eve.dayOfWeek}
                    time={eve.time}
                    speaker={eve.speaker}
                    speakerAvatarUrl={eve.speakerAvatarUrl}
                    topics={eve.topics}
                    eventJoinLink={eve.joinLink}
                  />
                );
              })
            ) : (
              <div className="px-6 py-8md:px-16 lg:px-56 flex flex-col justify-center items-center">
                <h1 className="text-zinc-200 text-xl lg:text-4xl uppercase tracking-wider font-bold mb-11 animate-pulse">
                  Admission Is Going On
                </h1>
                <div className="flex flex-col md:flex-row justify-around items-center gap-9">
                  {/* <div className="w-80 h-96 border border-transparent bg-white rounded-lg">
                    <img
                      src="https://img.freepik.com/free-vector/gradient-ui-ux-background_23-2149047011.jpg?t=st=1723383930~exp=1723387530~hmac=771c6d412b611d5b8071f561ee3f2c9c3062c0a863c1d0609fda8fc4fe8c4388&w=1380"
                      className="w-full h-56 rounded-tr-lg rounded-tl-lg"
                      alt="ui ux banner"
                    />
                    <h1 className="text-center py-5 text-zinc-700 uppercase font-semibold text-lg">
                      UI/UX & Graphic Design
                    </h1>
                    <h2 className="text-center pb-5 text-zinc-700 text-xs">
                      Faculty: <span>Sharmistha Chatterjee</span>
                    </h2>
                    <div className="flex justify-around items-center">
                      <Link
                        href={
                          "https://youtube.com/playlist?list=PLCA5omKpatF9AjHXtlUsMr0EFUE_VYh7P&si=fgJBJzChwxsTeAQC"
                        }
                        target="_blank"
                      >
                        <button className="bg-red-500 hover:bg-red-600 text-white rounded-3xl text-[10px] w-36 h-10">
                          Free Masterclasses
                        </button>
                      </Link>
                      <Link
                        href={
                          "https://api.whatsapp.com/send/?phone=919051977167&text=%22I%20am%20from%20Vidyastu%20website.%22&type=phone_number&app_absent=0"
                        }
                        target="_blank"
                      >
                        <button className=" border border-black rounded-3xl text-[10px] w-36 h-10">
                          Join Now
                        </button>
                      </Link>
                    </div>
                  </div> */}
                  {/* <div className="w-80 h-96 border border-transparent bg-white rounded-lg">
                    <img
                      src="https://cdn.pixabay.com/photo/2015/02/03/23/41/paper-623167_1280.jpg"
                      className="w-full h-56 rounded-tr-lg rounded-tl-lg"
                      alt="content writing banner"
                    />
                    <h1 className="text-center py-5 text-zinc-700 uppercase font-semibold text-[15px]">
                      Content Writing
                    </h1>
                    <h2 className="text-center pb-5 text-zinc-700 text-xs">
                      Faculty: <span>Priyam Sengupta, Sayani Dutta</span>
                    </h2>
                    <div className="flex justify-around items-center">
                      <Link
                        href={
                          "https://youtu.be/wxFxRYr7lVs?si=hbr2sk7R0HCjK8UJ"
                        }
                        target="_blank"
                      >
                        <button className="bg-red-500 hover:bg-red-600 text-white rounded-3xl text-[10px] w-36 h-10">
                          Free Masterclasses
                        </button>
                      </Link>
                      <Link
                        href={
                          "https://api.whatsapp.com/send/?phone=919051977167&text=%22I%20am%20from%20Vidyastu%20website.%22&type=phone_number&app_absent=0"
                        }
                        target="_blank"
                      >
                        <button className=" border border-black rounded-3xl text-[10px] w-36 h-10">
                          Join Now
                        </button>
                      </Link>
                    </div>
                  </div> */}
                </div>
                {/* <h2 className="text-zinc-200 text-md lg:text-4xl uppercase tracking-wider font-bold my-11 animate-pulse">
                  Upcoming Courses
                </h2> */}
                <div className="flex flex-col md:flex-row justify-around items-center gap-9">
                  <div className="w-80 h-[28rem] border border-transparent bg-white rounded-lg">
                    {/* <div className="absolute -mt-3 px-4 py-2 -ml-8 md:-ml-10 bg-red-600 text-black font-bold uppercase rounded-2xl z-40">New Syllabus</div> */}
                    <img
                      src="https://cdn.pixabay.com/photo/2022/05/27/11/18/ux-design-7224948_960_720.png"
                      className="w-full h-56 rounded-tr-lg rounded-tl-lg"
                      alt="content writing banner"
                    />
                    <h2 className="text-center py-5 text-zinc-700 uppercase font-semibold text-lg">
                      UI/UX & Graphic Design
                    </h2>
                    <h2 className="text-center pb-5 h-8 text-zinc-700 text-xs">
                      Faculty: <span>Sharmistha Chatterjee</span>
                    </h2>
                    <h2 className="text-center mb-2 text-red-700 font-bold animate-pulse text-md">
                      Class will start from 25th January, 2025
                    </h2>
                    <Link href="tel:+917450010203" className="font-semibold text-red-700">
                      <div className="flex justify-center bg-yellow m-5 p-3 border rounded">
                        <button className="font-bold text-xl">
                          Call Now
                        </button>
                      </div>
                    </Link>
                    {/* <div className="flex justify-center">
                      <button className="border border-zinc-700 px-2 py-1 rounded-2xl">
                        Call Now at <Link href="tel:+917450010203" className="font-semibold text-red-700">+91-7450010203</Link>
                      </button>
                    </div> */}
                    {/* <div className="flex justify-around items-center">
                      <Link
                        href={
                          "https://youtu.be/wxFxRYr7lVs?si=hbr2sk7R0HCjK8UJ"
                        }
                        target="_blank"
                      >
                        <button className="bg-red-500 hover:bg-red-600 text-white rounded-3xl text-[10px] w-36 h-10">
                          Free Masterclasses
                        </button>
                      </Link>
                      <Link
                        href={
                          "https://api.whatsapp.com/send/?phone=919051977167&text=%22I%20am%20from%20Vidyastu%20website.%22&type=phone_number&app_absent=0"
                        }
                        target="_blank"
                      >
                        <button className=" border border-black rounded-3xl text-[10px] w-36 h-10">
                          Join Now
                        </button>
                      </Link>
                    </div> */}
                  </div>

                  <div className="w-80 h-[28rem] border border-transparent bg-white rounded-lg">
                    <img
                      src="https://cdn.pixabay.com/photo/2016/07/19/09/03/digital-marketing-1527799_960_720.png"
                      className="w-full h-56 rounded-tr-lg rounded-tl-lg"
                      alt="dm banner"
                    />
                    <h1 className="text-center py-5 text-zinc-700 uppercase font-semibold text-lg">
                      Digital Marketing
                    </h1>
                    <h2 className="text-center pb-5 h-8 text-zinc-700 text-xs">
                      Faculty: <span>Nilanjan Bhadra</span>
                    </h2>
                    <h2 className="text-center mb-2 text-red-700 font-bold animate-pulse text-md">
                      Class will start from 25th January, 2025
                    </h2>
                    <Link href="tel:+917450010203" className="font-semibold text-red-700">
                      <div className="flex justify-center bg-yellow m-5 p-3 border rounded">
                        <button className="font-bold text-xl">
                          Call Now
                        </button>
                      </div>
                    </Link>
                    {/* <div className="flex justify-center">
                      <button className="border border-zinc-700 px-2 py-1 rounded-2xl">
                        Call Now at <Link href="tel:+919088844490" className="font-semibold text-red-700">+91-9088844490</Link>
                      </button>
                    </div> */}
                    {/* <div className="flex justify-around items-center">
                      <Link
                        href={"https://www.youtube.com/watch?v=b32sU5rnrRg"}
                        target="_blank"
                      >
                        <button className="bg-red-500 hover:bg-red-600 text-white rounded-3xl text-[10px] w-36 h-10">
                          Free Masterclasses
                        </button>
                      </Link>
                      <Link
                        href={
                          "https://api.whatsapp.com/send/?phone=919051977167&text=%22I%20am%20from%20Vidyastu%20website.%22&type=phone_number&app_absent=0"
                        }
                        target="_blank"
                      >
                        <button className=" border border-black rounded-3xl text-[10px] w-36 h-10">
                          Join Now
                        </button>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSection;
