"use client";

import React from "react";
import { InfiniteMovingCards } from "@/components/ui/ReviewMovingCard";

export function Review() {
  return (
    (<div
      className="h-[22rem] rounded-md flex flex-col antialiased bg-white dark:bg-grid-white/[0.05] items-center justify-center relative overflow-hidden">
      <InfiniteMovingCards items={testimonials} direction="right" speed="slow" />
    </div>)
  );
}

const testimonials = [
  {
    quote:
      "Nirmalyada eto easily python ta sikhiyechile bolei amar ekhon basic concept ta clear hye geche..ar Java sikhte osubidha hocchena ekdom e..plus recording gulo o diyechile..jokhon e kono osubidha hoy kono topic e,sei video gulo dekhe ni.",
    name: "Riya Mondal",
    title: "",
    imgurl: "/images/reviewer4.png",
  },
  {
    quote:
      "Content writing er class kore amr vison valo legeche. Puro bisoy ta Bangla e bojhanor jonno aro vlo jeno bujhte subidhe hoeche. Sayani ma'am ashadharon vabe bojhan ...onar class korte khub e valo lage.recently onar e internship er team e join korechi and kaj o sikhchi.  ❤️❤️",
    name: "Shatabdi Banerjee",
    title: "",
    imgurl: "/images/reviewer10.jpg",
  },
  {
    quote: "Tushar Sir's class on Full Stack Web Development has been incredibly beneficial for me. He enhanced my skills and confidence in Full Stack Web Development.",
    name: "Ipsita Mukherjee",
    title: "",
    imgurl: "/images/reviewer7.png",
  },
  {
    quote:
      "এক দাদার কাছে vidyastur ব্যাপারে জানতে পেরে নির্মাল্যদাকে ফোন করেছিলাম বড্ড puzzle ছিলাম কোর্সটা করার আগে। ফরিদ স্যার খুবই ভালো করে সবটা বুঝিয়ে ক্লাস করাতেন লাস্ট ক্লাস শেষের পরদিনই জব হয়ে গেলো...আগে জানিনা কি হবে না হবে তবে thanks to nirmalya da❤️",
    name: "Sudipto Ghosh",
    title: "",
    imgurl: "/images/reviewer5.png",
  },
  {
    quote:
      "I joined vidyastu to take training for MERN stack . My teacher Farid sir is highly skilled and a great teacher. I got a job at Msqube technology solutions pvt ltd.",
    name: "Anirban Mukherjee",
    title: "MSQube Technology Solutions Pvt Ltd",
    imgurl: "/images/reviewer6.png"
  },
  {
    quote:
      "I decided to enroll at Fullstack Development after a few classes my experience was relatively good, the faculty teachers were very knowledgeable, the entire @vidyastu community is very helpful and create a great ambiance to learn thanks Vidyastu",
    name: "Dwipen Dutta",
    title: "",
    imgurl: "/images/reviewer8.jpg"
  },
  {
    quote:
      "I joined the digital marketing course and had a fantastic learning experience. The offline batch offered hands-on experience with real-world scenarios. I am  particularly grateful to Kaushik Sir for his guidance and support during the training. I was hired as an SEO Analyst at Archtech Design. I strongly recommend Vidyastu .",
    name: "Sourav Naskar",
    title: "Archtech Design",
    imgurl: "/images/reviewer9.jpeg"
  },
  {
    quote:
      "Vidyastu has helped me a lot to understand the core of digital marketing. Nilanjan sir was extremely helpful and help us with patience. He enlightened us the details of interview process that has helped me to get my job in digital marketing. Thank you entire vidyastu team to guide us.",
    name: "Nivedita Ghosh",
    title: "SEO Analyst at ZTS infotech Pvt Ltd",
    imgurl: "/images/reviewer20.jpeg"
  },
  {
    quote:
      "আমার নাম তানিসা মজুমদার। আমি একজন কলেজ পড়ুয়া ছাত্রী, ভূগোল অনার্স, দ্বীতিয় বৎসর চলছে। এটার সাথে বিদ্যাস্তুর কনটেন্ট রাইটিং কোর্সটি আমি করেছি, এবং সেখানে সায়নী ম্যাম এর ক্লাস ভীষন ভালো। যথেষ্ট যত্ন করে ক্লাস করাতেন এবং কোনো বিষয় বোঝানোর ক্ষেত্রে খুব সহজ করে বুঝিয়ে দিতেন। আর নিউজ কনটেন্ট রাইটিং আমাদের প্রিয়ম স্যার করিয়েছেন তিনিও সুন্দর ধরে ধরে বিষয় গুলো শিখিয়েছেন। ধন্যবাদ টিম বিদ্যাস্তুকে 🙏",
    name: "Tanisha Majumder",
    imgurl: "/images/reviewer21.jpeg"
  },
  {
    quote:
      "অ্যাডমিশন কনসালটেন্ট পৃথা ম্যাডাম কাউন্সেলিং ও সঠিক গাইডেন্সের মাধ্যমে  আমাকে মোটিভেট করেন ফলে আমি কনটেন্ট রাইটিং কোর্সে ভর্তি হই। ক্লাসের প্রথম দিন থেকেই আমাদের এডুকেটর সায়নী ম্যাডাম ও প্রিয়ম স্যার এতো যত্ন নিয়ে ক্লাস করাতেন ও আমাদের উৎসাহিত করতেন যে ক্লাস করার আগ্রহ অনেকগুন বেড়ে যেত। আর যে অপরিসীম ধৈর্য্য নিয়ে বারবার আমাদের ডাউট ক্লিয়ার করে দিতেন, অ্যাসেসমেন্ট চেক করে ভুল-ত্রুটি সংশোধন করে দিতেন তারজন্য সত্যিই অনেক কিছু শিখতে পেরেছি। বিদ্যাস্তুর স্যার ম্যাডামদের জন্যই আজ আমি কনটেন্ট লিখে উপার্জন করতে সক্ষম। অসংখ্য ধন্যবাদ বিদ্যাস্তুকে",
    name: "Ramyani Sen Roy",
    imgurl: "/images/reviewer22.jpeg"
  },
];
